<!-- eslint-disable prettier/prettier -->
<template>
    <div id="el">
        <topbarHome></topbarHome>
        <navbarHome></navbarHome>
        <a
            v-show="scY"
            href="#"
            class="btn btn-lg btn-primary rounded-0 btn-lg-square back-to-top"
            ><font-awesome-icon icon="fa-solid fa-angles-up"
        /></a>

        <router-view />
    </div>
</template>

<script>
    // @ is an alias to /src

    import topbarHome from '@/components/topbarHome.vue';
    import navbarHome from '@/components/navbarHome.vue';
    export default {
        name: 'HomeView',
        el: '#app',
        data() {
            return {
                scTimer: 0,
                scY: 0,
                currentPage: '',
            };
        },
        components: {
            topbarHome,
            navbarHome,
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            console.log(window.scrollY);
            var url = new URL(window.location.href);
            if (url.toString().includes('blogs')) {
                document
                    .getElementsByClassName('active')[0]
                    .classList.remove('active');
                document.getElementById('blogsLink').classList.add('active');
            } else if (url.toString().includes('about')) {
              document
                    .getElementsByClassName('active')[0]
                    .classList.remove('active');
                document.getElementById('aboutLink').classList.add('active');
            } else if (url.toString().includes('Contact')) {
              document
                    .getElementsByClassName('active')[0]
                    .classList.remove('active');
                document.getElementById('contactLink').classList.add('active');
            }
            else if (url.toString().includes('Terms&Condition')) {
              document
                    .getElementsByClassName('active')[0]
                    .classList.remove('active');
               
            }else{
              document
                    .getElementsByClassName('active')[0]
                    .classList.remove('active');
                    document.getElementById('homeLink').classList.add('active');
               
            }
        },
        methods: {
            handleScroll: function () {
                if (window.scrollY > 10) {
                    this.scY = 1;
                } else {
                    this.scY = 0;
                }
            },
        },
        watch: {
            // whenever question changes, this function will run
            scY: function (n, w) {
                console.log(n, w);

                if (n <= 500) {
                    this.items = 1;
                } else {
                    this.items = 2;
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '../src/assets/scss/style.scss';
</style>
