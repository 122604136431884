<!-- eslint-disable prettier/prettier -->
<template>
    <div class="container-fluid " style="background-color: #2e3092;">
        <div class="row py-2 px-lg-5">
            <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                <div class="d-inline-flex align-items-center text-white">
                    <small class="pr-1"
                        >  
                         <font-awesome-icon icon="fa-solid fa-phone"  /></small
                    >
                   
                    <small  id="phone"
                        >  
                        </small
                    >
             
                    <small class="px-3">|</small>
                    <small class="pr-1"
                        >  <font-awesome-icon icon="fa-solid fa-envelope"   /></small
                    >
                    <small  id="email"
                        >  
                        </small
                    >
                </div>
            </div>
            <div class="col-lg-6 text-center text-lg-right">
                <div class="d-inline-flex align-items-center">
                 
                    <a id ="whats-app" class="text-white px-2" href="" target="_blank">
                        <font-awesome-icon icon="fa-brands fa-whatsapp" />
                    
                    </a>
                    <a id ="facebook" class="text-white px-2" href="" target="_blank">
                        <font-awesome-icon icon="fa-brands fa-facebook-f"  /> 
                  
                    </a>
               
                    <a  id ="telegram" class="text-white px-2" href="" target="_blank">
                        <font-awesome-icon icon="fa-brands fa-telegram" style="background-color: transparent;" />
                       
                    </a>
                   
                    <a  id ="youtube"  class="text-white pl-2" href="" target="_blank">
                        <font-awesome-icon icon="fa-brands fa-youtube"  /> 
                       
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'topbarHome',
        props: {},
    };
</script>
