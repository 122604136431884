import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
/*vue play*/
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faUserSecret);
library.add(faPhone);
library.add(faEnvelope);
library.add(faFacebookF);
library.add(faTwitter);
library.add(faLinkedinIn);
library.add(faInstagram);
library.add(faYoutube);
library.add(faTelegram);
library.add(faWhatsapp);
library.add(faUser);
library.add(faStar);
library.add(faLocationDot);
library.add(faAngleLeft);
library.add(faBookOpenReader);
library.add(faAnglesUp);
library.add(faQuoteLeft);
library.add(faQuoteRight);
library.add(faClock);
library.add(faPlay);

createApp(App)
  .use(store)
  .use(router)
  .use(VuePlyr)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
