<!-- eslint-disable prettier/prettier -->
<template>
   <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row align-items-center"> 
                <div class="rtl-dir dir-rtl col-lg-5 mb-5 mb-lg-0">
                    <div class="bg-light d-flex flex-column justify-content-center px-5" style="height: 425px;">
                        <h6 id="need_help" class="d-inline-block position-relative section-title text-uppercase pb-2">Need Help?</h6>
                        
                        <div class="d-flex align-items-center mb-5">
                            <div class="btn-icon bg-secondary ml-4">
                                <font-awesome-icon class="con-us-icon" icon="fa-solid fa-phone"  style="color: #fafafa;"/>
                            </div>
                            <div class="mt-n1">
                                <h4> اتصل بنا </h4>
                                <p  id ="phone_A" class="m-0"></p>
                                <p  id ="phone_A_2" class="m-0"></p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="btn-icon bg-warning ml-4">
                                <font-awesome-icon  class="con-us-icon" icon="fa-solid fa-envelope"  style="color: #fafafa;"/>
                            </div>
                            <div class="mt-n1">
                                <h4> الايميل</h4>
                                <p id="email_A" class="m-0 email_c"></p>
                                <p id="email_A_2" class="m-0 email_c"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" rtl-dir col-lg-7">
                       <img class=" w-100 h-100" src="../assets/img/students.jpg" >
                
                    </div>
                
               
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'contactUS',
        props: {},
    };
</script>
