<!-- eslint-disable prettier/prettier -->
<template>
   <div class=" container-fluid py-5 ">
        <div class=" container py-5">
            <div class="row " >
                <div class="rtl-dir col-lg-7">
                    <div class=" section-title position-relative mb-4">
                        <h6 class=" d-inline-block position-relative section-title text-uppercase pb-2">عن التطبيق</h6>
                        <h1 class=" display-4" id = "about_heading"></h1>
                    </div>
                    <p id ="about_text"></p>
                 
                </div>
                <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px;">
                    <div class="position-relative h-100 ">
                        <img class="position-absolute w-100 h-100 " src="../assets/img/about.png" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'aboutAs',
        props: {},
    };
</script>